<template>
  <div class="row mb-3">

    <ed-input-auto-complete
      class="col-xs-12 pl-4 col-sm-12 col-md-6 col-lg-6 col-xl-6"
      v-model="formData.intCadastroGeralEmpresaId"
      label="Empresa"
      name="intCadastroGeralEmpresaId"
      route="Cadastro/CadastroGeral"
      :filters="{
        intTipoCadastroGeralId:58
      }"
      :readonly="boolDesabilitarUnidadeTrabalho"
      initializeItems
    />

    <ed-input-auto-complete
      class="col-xs-12 pl-4 col-sm-12 col-md-6 col-lg-6 col-xl-6"
      v-model="formData.intCadastroGeralUnidadeId"
      label="Unidade de Trabalho"
      name="intCadastroGeralUnidadeId"
      route="Cadastro/CadastroGeralUnidade"
      :filters="{
        intCadastroGeralEmpresaId: this.formData.intCadastroGeralEmpresaId
      }"
      :readonly="boolDesabilitarUnidadeTrabalho"
      initializeItems
    />


    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
      <ed-alert
        v-if="!objRevisao && boolCarregado"
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ma-1"
        type="warning"
      >
        Nenhum PCMSO encontrado para essa Unidade de Trabalho
      </ed-alert>
    </div>

    <div
      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
      v-if="objRevisao"
    >
      <v-card
        class="mb-5 pb-4"
        v-for="(objRevisaoSetor, index) in objRevisao.setores"
        hover
        :key="index"
      >
        <v-card-title>
          <div class="col-12 pa-0 ma-0 text-left">
            <ed-button
              :iconLeft="
                (!objRevisaoSetor.boolExibirSetor
                  ? $utilidade.getIcone('setaBaixo')
                  : $utilidade.getIcone('setaCima')) + ' fa-3x'
              "
              color="success"
              title="Editar Setor"
              :label="
                objRevisaoSetor.intOrdem + ' (' + objRevisaoSetor.strNome + ')'
              "
              text
              @click="ocultarExibirSetor(objRevisaoSetor)"
            />
          </div>
        </v-card-title>
        <v-card-subtitle>
          <div
            class="col-12"
            v-show="
              !objRevisaoSetor.boolExibirSetor && !intCadastroGeralSetorId
            "
          >
            Clique no nome do setor para visualizar mais
          </div>
        </v-card-subtitle>
        <div
          class="col-12"
          v-show="
            objRevisaoSetor.boolExibirSetor ||
            objRevisaoSetor.intCadastroGeralSetorId == intCadastroGeralSetorId
          "
        >
          <div class="row ma-0 pa-0">
            <div
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4
                border
                mt-3
              "
            >
              <h4><i :class="$utilidade.getIcone('funcao')"></i> Funções</h4>
              <funcao :formData="objRevisaoSetor" boolDisableControls />
            </div>

            <div
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4
                border
                mt-3
              "
            >
              <h4><i :class="$utilidade.getIcone('risco')"></i> Riscos</h4>
              <risco :formData="objRevisaoSetor" boolDisableControls />
            </div>

            <div
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4
                border
                mt-3
              "
            >
              <h4><i :class="$utilidade.getIcone('exame')"></i> Exames</h4>
              <exame :formData="objRevisaoSetor" boolDisableControls />
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import {
  EdSimpleTable,
  EdInputSelect,
  EdInputAutoComplete,
  EdButton,
  EdAlert,
} from "@/components/common/form";
import Funcao from "@/components/documento/partials/setores-funcao.vue";
import Risco from "@/components/documento/partials/setores-risco.vue";
import Exame from "@/components/documento/partials/setores-exame.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    intCadastroGeralEmpresaId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    intCadastroGeralUnidadeId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    intCadastroGeralSetorId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    intTipoDocumentoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    boolDesabilitarRevisao: {
      type: [Boolean],
      required: false,
      default: false,
    },
    boolDesabilitarUnidadeTrabalho: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  components: {
    EdSimpleTable,
    EdInputSelect,
    EdInputAutoComplete,
    EdButton,
    EdAlert,
    Funcao,
    Risco,
    Exame,
  },
  provide() {
    return {
      getDetalheRevisao: null,
      onEditarFuncao: null,
      onEditarRisco: null,
      onEditarExame: null,
      bind:this.bind
    };
  },
  mounted() {
    this.getRevisoes();
  },
  created() {},
  data() {
    return {
      formData: {
        intCadastroGeralEmpresaId: Number(this.intCadastroGeralEmpresaId),
        intCadastroGeralUnidadeId: Number(this.intCadastroGeralUnidadeId),
        intCadastroGeralSetorId: Number(this.intCadastroGeralSetorId),
        intRevisaoId: null,
      },
      objRevisao: null,
      boolCarregado: false,
      objRevisao: [],
      bind:{
        objTipoDocumnento:{}
      }
    };
  },
  watch: {
    intCadastroGeralSetorId() {
      this.validarSetor();
    },
    intCadastroGeralEmpresaId() {
      this.formData.intCadastroGeralUnidadeId = null;
      this.objRevisao = null;

      this.formData.intCadastroGeralUnidadeId = Number(
        this.intCadastroGeralUnidadeId
      );
    },
    intCadastroGeralUnidadeId() {
      this.formData.intCadastroGeralUnidadeId = Number(
        this.intCadastroGeralUnidadeId
      );
    },
    "formData.intCadastroGeralUnidadeId"() {
      this.getRevisoes();
    },
    "formData.intRevisaoId"() {
      this.getDetalheRevisao();
    },
  },
  computed: {},
  methods: {
    validarSetor() {
      let boolSetorEncontrado = false;

       this.$emit("apagarAviso", null);
       
      if (this.objRevisao && this.objRevisao.setores) {
        this.objRevisao.setores.forEach((objRevisaoSetor) => {
          if (
            this.intCadastroGeralSetorId ==
            objRevisaoSetor.intCadastroGeralSetorId
          ) {
            boolSetorEncontrado = true;
          }
        });

        if (this.intCadastroGeralSetorId && !boolSetorEncontrado) {
          this.$emit("avisos", {
            strAviso: "Setor não encontrado no PCMSO",
            strTipoAviso: "warning",
          });
        }
      }

      return boolSetorEncontrado;
    },

    setUltimaRevisao(arrayItems) {
      if (!this.formData.intRevisaoId && arrayItems && arrayItems.length) {
        this.formData.intRevisaoId = arrayItems[arrayItems.length - 1].intId;
      }
    },

    getDetalheRevisao() {
      if (!this.formData.intRevisaoId) return;

      this.$root.$dialog.loading(true);

      this.objRevisao = null;

      this.$root.$request
        .get("Documento/Revisao", {
          intId: this.formData.intRevisaoId,
        })
        .then((objResult) => {
          if (objResult.status == 200 && objResult.result) {
            this.objRevisao = objResult.result;
          }

          this.validarSetor();
          this.boolCarregado = true;
          this.$root.$dialog.loading(false);
        });
    },

    getRevisoes() {
      this.$root.$dialog.loading(true);

      this.objRevisao = [];
      this.boolCarregado = false;
      this.objRevisao = null;
      this.$emit("apagarAviso", null);

      this.$root.$request
        .get("Documento/Revisao", {
          intCadastroGeralUnidadeId: this.formData.intCadastroGeralUnidadeId,
          intTipoDocumentoId: 279,
          intTipoRevisaoId: 285,
          boolBuscarRevisaoPorUnidade: true,
          boolBuscarUltimaRevisaoLiberada: true,
        })
        .then((objResult) => {

          if (objResult.status == 200 && objResult.result) {
            this.objRevisao = objResult.result;
          }

          if (!this.objRevisao) {
            this.$emit("avisos", {
              strAviso: "Essa Unidade de Trabalho não possui um PCMSO ativo",
              strTipoAviso: "warning",
            });
          }

          if (!this.formData.intRevisaoId && this.objRevisao) {
            this.formData.intRevisaoId = this.objRevisao.intId
          } else {
            this.boolCarregado = true;
          }

          this.$root.$dialog.loading(false);
        });
    },

    ocultarExibirSetor(objSetor) {
      if (objSetor.boolExibirSetor == undefined) {
        objSetor.boolExibirSetor = false;
      }

      objSetor.boolExibirSetor = !objSetor.boolExibirSetor;
      this.$forceUpdate();
    },
  },
};
</script>
