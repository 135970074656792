<template>
  <div>
    <v-timeline class="mb-5" align-top dense>
      <v-timeline-item
        v-for="(objConsulta, index) in arrayConsulta"
        :key="index"
        color="green"
        fill-dot
      >
        <v-card color="#eeeeee" light>
          <v-card-title class="text-h6">
            <b
              >{{ $utilidade.toDate(objConsulta.strDataConsulta, true) }} |
              CONSULTA Nº {{ objConsulta.intId }}</b
            >
            <span
              style="display: block; font-size: 10pt; width: 100%"
              class="text-left pb-2"
            >
              <v-breadcrumbs
                :items="getTipoConsulta(objConsulta)"
                class="pa-0 text-right"
              >
                <template v-slot:divider>
                  <v-icon>{{ $utilidade.getIcone("setaDireita") }}</v-icon>
                </template>

                <template v-slot:item="{ item }">
                  <v-breadcrumbs-item>
                    <v-chip color="green" class="mr-1 mt-1" outlined  small>
                      {{ item.text }}
                    </v-chip>
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>
            </span>
          </v-card-title>
          <v-card-text class="white text--primary text-left">
            <div class="row">
              <div class="col-12 pb-0">
                <h5 style="color: #666666">EXAMES REALIZADOS</h5>
              </div>
              <div class="col-12 pt-0 pb-3">
                <ed-simple-table
                  :config="{ registerName: 'Inserir novo exame' }"
                  disabledRegisterRows
                  disabledSearchRows
                >
                  <thead>
                    <tr>
                      <th width="50">#</th>
                      <th>Exame</th>
                      <th>Data</th>
                      <th>Status</th>
                      <th>Resultado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="(objConsultaExame, index) in objConsulta.exames"
                    >
                      <tr :key="index" v-if="!objConsultaExame.aproveitamento">
                        <td align="left">{{ index + 1 }}</td>
                        <td align="left">
                          {{ objConsultaExame.exame.strNome }}
                        </td>
                        <td align="left">
                          {{
                            $utilidade.toDate(
                              objConsultaExame.strDataExame,
                              true
                            )
                          }}
                        </td>
                        <td align="left">
                          {{ objConsultaExame.status.strNome }}
                        </td>
                        <td align="left">
                          {{objConsultaExame.strResultado ? objConsultaExame.strResultado  : '---'}}
                        </td>
                      </tr>
                    </template>

                    <template
                      v-for="(objConsultaExame, index) in objConsulta.exames"
                    >
                      <tr
                        class="ed-consulta-aproveitamento"
                        :key="index"
                        v-if="objConsultaExame.aproveitamento"
                      >
                        <td align="left"></td>
                        <td align="left">
                          {{ objConsultaExame.exame.strNome }}
                          <span
                            style="
                              width: 100%;
                              display: block;
                              font-size: 10pt;
                              color: gray;
                            "
                          >
                            Exame aproveitado da consulta
                            {{ objConsultaExame.aproveitamento.intId }}
                          </span>
                        </td>
                        <td align="left">
                          {{
                            $utilidade.toDate(
                              objConsultaExame.strDataExame,
                              true
                            )
                          }}
                        </td>
                        <td align="left">
                          {{ objConsultaExame.status.strNome }}
                        </td>
                        <td align="left"></td>
                      </tr>
                    </template>
                    <tr v-if="!objConsulta.exames || !objConsulta.exames.length">
                      <td colspan="5" align="center">
                        Nenhum exame encontrado
                      </td>
                    </tr>
                  </tbody>
                </ed-simple-table>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    <ed-pagination
      class="mt-3"
      v-if="objPagination"
      :pagination="objPagination"
      @page="getConsulta"
    />
  </div>
</template>

<script>
import { EdSimpleTable, EdPagination } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    intFuncionarioId: {
      type: [Number, String],
      required: true,
      default: null,
    },
    intConsultaAtualId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  components: { EdSimpleTable, EdPagination },
  mounted() {
    this.getConsulta();
  },
  beforeDestroy() {
    this.arrayConsulta = [];
  },
  created() {},
  data() {
    return {
      objPagination: null,
      formFiltros:{
        intFuncionarioId:null
      },
      arrayConsulta: [],
    };
  },
  watch: {
    intFuncionarioId() {
      this.getConsulta();
    },
    intConsultaAtualId() {},
  },
  methods: {
    getTipoConsulta(objConsulta) {
      let arrayTipo = [];

      arrayTipo.push({ text: objConsulta.grupo.strNome });
      arrayTipo.push({ text: objConsulta.sub_grupo.strNome });

      if (objConsulta.intMeses) {
        arrayTipo.push({ text: objConsulta.intMeses + " meses" });
      }

      return arrayTipo;
    },

    getConsulta() {
      if (!this.intFuncionarioId) return;

      this.formFiltros['intFuncionarioId'] = this.intFuncionarioId;
      
      this.$root.$dialog.loading(true);
      this.arrayConsulta = [];


      this.$root.$request
        .get("Consulta/Consulta", this.formFiltros)
        .then((objResult) => {
          this.$root.$dialog.loading(false);

          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.arrayConsulta = objResult.result.data;
          }
        });
    },
  },
};
</script>
